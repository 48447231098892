import { isPast } from 'date-fns'

export const flattenObject = ob => {
  const toReturn = {}

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue

    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i])
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue

        toReturn[`${i}.${x}`] = flatObject[x]
      }
    } else {
      toReturn[i] = ob[i]
    }
  }
  return toReturn
}

// converts an object into a query string
// ex: {authorId : 'abc123'} -> &authorId=abc123
export const objToQueryString = obj =>
  Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&')

export const toPascalCase = string =>
  string.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function(match) {
    return match.charAt(match.length - 1).toUpperCase()
  })

export const toCamelCase = string => {
  const camelCase = toPascalCase(string)
  return camelCase.charAt(0).toLowerCase() + camelCase.substring(1)
}

/**
 * Simple function to remove dates that are in the
 *
 * @param   {array}  dates  dates to check in format YYYY-MM-DD
 *
 * @return  {array}         the dates that passed
 */
export const validDates = (dates = []) => {
  if (!Array.isArray(dates)) throw new Error('An array of dates was not supplied')

  return dates.filter(date => !isPast(new Date(date).setHours(0, 0, 0, 0)))
}

/**
 * Take an array and turn it into an object with the array values as the keys with the provided value
 *
 * @param   {array}     arr    The Array to convert
 * @param   {any}       value  Anything you want
 *
 * @return  {obj}       and object
 */
export const arrToObj = (arr = [], value = '', keyPrefix = '', keySuffix = '') => {
  if (!Array.isArray(arr)) throw new Error('An array of dates was not supplied')

  const newArr = arr.reduce((acc, cur) => {
    acc[keyPrefix + cur + keySuffix] = value
    return acc
  }, {})

  console.log(arr, value, newArr)
  return newArr
}
