import { useState, useEffect, useRef } from 'react'
import { useCountRenders } from './useCountRenders'
// Context
import { useMessage } from '../context/message-provider'

const useFetch = options => {
  const [fetchOptions, setFetchOptions] = useState(() => options)
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { addMessage } = useMessage()
  // seCountRenders('Fetch Renders')

  useEffect(() => {
    if (!fetchOptions) return

    const { method = 'GET', endpoint, body, successCallback = () => {}, errorCallback } = fetchOptions

    if (!endpoint) {
      throw new Error('Endpoint was not supplied for fetch action')
    }

    const requestOptions = {
      method,
      headers: {
        Accept: 'application/json',
      },
    }

    if (body) {
      requestOptions.headers['Content-Type'] = 'application/json'
      requestOptions.body = JSON.stringify(body)
    }

    const fetchData = async () => {
      setIsLoading(true)

      try {
        const res = await fetch(endpoint, requestOptions)
        // console.log('FETCH RESULT', res)
        if (!res.ok) {
          const error = await res.json()

          throw error
        }

        const json = await res.json()

        setIsLoading(false)
        setResponse(json)
        successCallback(json)
        // Below is a clean up
        setFetchOptions()
      } catch (error) {
        console.error(error)
        setIsLoading(false)
        setFetchOptions()

        // This determines how we want to display errors. If there isn't a callback, then we use the message Context
        if (errorCallback) {
          errorCallback(error)
        } else {
          addMessage(error)
        }
        // setError(error.message)
      }
    }
    fetchData()
  }, [addMessage, fetchOptions])

  return { setFetchOptions, isLoading, response, error }
}

export default useFetch
